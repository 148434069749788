import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Header from "../components/header"
import {
  Section,
  Content,
  Intro,
  BoxContainer,
  Button,
} from "../components/primitives"
import { AboutPageAndTitleMarkQuery } from "../../graphql-types"
import Team from "../components/team"
import NewsletterSignup from "../components/newsletterSignup"
import SEO from "../components/seo"
import Swiper from "react-id-swiper"

const params = {
  slidesPerView: 1,
  centeredSlides: true,
  spaceBetween: 20,
}

export const AboutPage: React.FC<{ data: AboutPageAndTitleMarkQuery }> = ({
  data,
}) => {
  const [activeSlide, updateActiveSlide] = React.useState<number>(0)
  const [swiper, setSwiper] = React.useState(null)

  const next = () => {
    if (swiper !== null) {
      swiper.slideNext()
    }
  }

  const prev = () => {
    if (swiper !== null) {
      swiper.slidePrev()
    }
  }

  React.useEffect(() => {
    if (swiper !== null) {
      swiper.on("slideChange", function() {
        updateActiveSlide(swiper.activeIndex)
      })
    }
  }, [swiper])

  return (
    <>
      <SEO data={data.wp?.page?.seo || undefined} wp={data.wp || undefined} />
      <Header
        fluid={
          data.wp?.page?.featuredImage?.localFile?.childImageSharp?.fluid ||
          undefined
        }
      />
      {data.wp.page?.blocks &&
        data.wp.page?.blocks.map((block, index) => {
          if (block?.__typename === "Wp_AcfIntroBlock")
            return (
              <Section
                key={index}
                css={`
                  background: ${props => props.theme.colors.sectionBackground};
                `}
              >
                <Intro>
                  <div>
                    {data.file?.childImageSharp && (
                      <Img fixed={data.file?.childImageSharp.fixed} />
                    )}
                    <h2
                      dangerouslySetInnerHTML={{
                        __html: block.acf?.title || "",
                      }}
                    />
                  </div>
                  <Content
                    dangerouslySetInnerHTML={{
                      __html: block.acf?.content || "",
                    }}
                  />
                </Intro>
              </Section>
            )
        })}

      {data.wp.persons?.edges && (
        <Section
          css={`
          position: relative;

          &:after {
            content: '';
            width: 100%;
            height: 100%;
            background-image: url('${require("../images/dark-border.png")}');
            opacity: ${props => (props.theme.theme === "dark" ? 1 : 0)};
            left: 0;
            top: 0;
            background-size: contain;
            background-position: center bottom;
            z-index: 2;
            position: absolute;
            background-repeat: no-repeat;
            pointer-events: none;
          }

          &:before {
            content: '';
            width: 100%;
            height: 100%;
            opacity: ${props => (props.theme.theme === "light" ? 1 : 0)};
            background-image: url('${require("../images/light-border.png")}');
            left: 0;
            top: 0;
            background-size: contain;
            background-position: center bottom;
            z-index: 2;
            position: absolute;
            background-repeat: no-repeat;
            pointer-events: none;
          }
      `}
        >
          <BoxContainer
            css={`
              display: flex;
              align-items: center;
              flex-direction: column;

              & > h3 {
                margin-bottom: 1.5rem;
                align-self: flex-start;
              }
            `}
          >
            <h3>Ontmoet het team</h3>
            <Team persons={data.wp.persons.edges || undefined} />
          </BoxContainer>
        </Section>
      )}
      <Section
        css={`
          background: ${props => props.theme.colors.sectionBackground};
          overflow: hidden;
        `}
      >
        <BoxContainer
          css={`
            text-align: center;
            width: 64rem;

            .swiper-container {
              overflow: visible;
              /* max-height: 60vh; */
            }

            .swiper-slide {
              opacity: 0.3;
              transition: 0.2s ease;

              &.swiper-slide-active {
                opacity: 1;
              }
            }
          `}
        >
          <h2
            css={`
              font-size: calc(2rem + 1.5vw);
              text-transform: uppercase;
              line-height: 1;
              font-family: ${props => props.theme.fonts.heading};
              display: flex;
              align-items: center;
              justify-content: center;
              text-align: center;
            `}
          >
            Wollegras in beeld
            <svg css={`
            margin-left: 1rem;
            align-self: flex-start;
            `} 
            xmlns="http://www.w3.org/2000/svg" width="36.645" height="32.064" viewBox="0 0 36.645 32.064">
          <g transform="translate(-1267 -2148.936)">
            <circle cx="7.5" cy="7.5" r="7.5" transform="translate(1278 2160)" fill="#339829"/>
            <path fill="currentColor" d="M1269.29 2150.797v-1.432a.431.431 0 01.43-.429h8.3a.431.431 0 01.429.429v1.431a.431.431 0 01-.429.429h-8.3a.431.431 0 01-.43-.428zm24.621 16.461a8.589 8.589 0 10-8.589 8.589 8.6 8.6 0 008.589-8.589zm-2.29 0a6.3 6.3 0 11-6.3-6.3 6.308 6.308 0 016.3 6.3zm-8.589 0a2.3 2.3 0 012.29-2.29 1.145 1.145 0 100-2.29 4.585 4.585 0 00-4.581 4.581 1.145 1.145 0 102.29 0zm20.613-14.887v25.194a3.436 3.436 0 01-3.435 3.435h-29.775a3.436 3.436 0 01-3.435-3.435v-20.613a3.436 3.436 0 013.435-3.435h9.734l2.4-3.206a3.437 3.437 0 012.753-1.375h14.887a3.436 3.436 0 013.436 3.435zm-20.613 1.146h17.177a3.56 3.56 0 011.145.193v-1.339a1.149 1.149 0 00-1.145-1.145h-14.887a1.153 1.153 0 00-.916.458zm18.322 3.435a1.149 1.149 0 00-1.145-1.145h-29.774a1.149 1.149 0 00-1.145 1.145v20.613a1.149 1.149 0 001.145 1.145h29.774a1.149 1.149 0 001.145-1.145z"/>
          </g>
        </svg>
          </h2>
          <div
            css={`
              display: flex;
              align-items: center;
              justify-content: center;
              margin-top: 3rem;
              margin-bottom: 1rem;
            `}
          >
            <Button onClick={() => prev()}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="47.221"
                height="15.485"
                viewBox="0 0 47.221 15.485"
              >
                <g fill="none" stroke="currentColor" strokeWidth="1.6">
                  <path d="M1.721 7.742h45.5" />
                  <path d="M8.308 14.919L1.131 7.742 8.308.565" />
                </g>
              </svg>
            </Button>
            <div
              css={`
                font-family: ${props => props.theme.fonts.heading};
                line-height: 1;
                text-transform: uppercase;
                font-size: 1rem;
                display: flex;
                align-items: center;
              `}
            >
              <span
                css={`
                  color: ${props => props.theme.colors.primary};
                  font-size: 1.5rem;
                `}
              >
                {activeSlide + 1}
              </span>
              <span
                css={`
                  color: ${props => props.theme.colors.border};
                  display: block;
                  margin-left: 0.5rem;
                  margin-right: 0.5rem;
                `}
              >
                /
              </span>
              <span>{data.wp.page?.acf?.gallery?.length}</span>
            </div>
            <Button onClick={() => next()}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="47.221"
                height="15.485"
                viewBox="0 0 47.221 15.485"
              >
                <g fill="none" stroke="currentColor" strokeWidth="1.6">
                  <path d="M45.5 7.743H0" />
                  <path d="M38.913.566l7.177 7.177-7.177 7.177" />
                </g>
              </svg>
            </Button>
          </div>
          <Swiper {...params} getSwiper={setSwiper}>
            {data.wp.page?.acf?.gallery?.map((item, i) => (
              <div key={i}>
                <Img fluid={item?.localFile?.childImageSharp?.fluid} />
                <div
                  css={`
                    margin-top: 1rem;
                    font-family: ${props => props.theme.fonts.heading};
                    text-transform: uppercase;
                    font-size: 1.5rem;
                  `}
                  dangerouslySetInnerHTML={{ __html: item?.caption }}
                />
              </div>
            ))}
          </Swiper>
        </BoxContainer>
      </Section>
      <NewsletterSignup />
    </>
  )
}

export const query = graphql`
  query AboutPageAndTitleMark($id: ID!) {
    wp {
      page(id: $id) {
        title
        uri
        featuredImage {
          ...FeaturedImage
        }
        blocks {
          ...Block
        }
        seo {
          ...SEO
        }
        acf {
          gallery {
            caption(format: RENDERED)
            sourceUrl
            localFile {
              childImageSharp {
                fluid(webpQuality: 100, maxWidth: 1024, maxHeight: 640) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
      persons {
        edges {
          node {
            persoon {
              role
            }
            id
            content
            title
            slug
            featuredImage {
              sourceUrl
              localFile {
                childImageSharp {
                  fluid(maxWidth: 480, maxHeight: 680, webpQuality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
    file(relativePath: { eq: "beeldmerk-black.png" }) {
      childImageSharp {
        fixed(width: 100, height: 245, webpQuality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`

export default AboutPage
