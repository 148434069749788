import React from "react"
import { Wp_Person } from "../../graphql-types"
import { Button, Content } from "./primitives"
import Img from "gatsby-image"
import styled from "styled-components"
import Swiper from "react-id-swiper"
import "swiper/css/swiper.css"

const params = {
  // pagination: {
  //   el: ".swiper-pagination",
  //   type: "bullets",
  //   clickable: true,
  // },
  // navigation: {
  //   nextEl: ".swiper-button-next",
  //   prevEl: ".swiper-button-prev",
  // },
  // spaceBetween: 30,
  slidesPerView: 1,
  centeredSlides: true,
  keyboard: true,
}

const ListItems = styled.ul`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(16rem, 1fr));
  gap: 2rem;
  width: 100%;

  li {
    width: 100%;
    padding-bottom: 100%;
    position: relative;
    background-color: #141414;
    display: block;
    cursor: pointer;

    & > * {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-start;
      color: ${props => props.theme.colors.white};
      padding: 1.5rem;
    }

    .gatsby-image-wrapper {
      opacity: ${props => (props.theme.theme === "light" ? 0.85 : 0.55)};
      border-radius: ${props => props.theme.borderRadius};
    }

    h5 {
      font-size: 1.25rem;
      margin-bottom: 0.5rem;
      font-weight: 700;
    }

    p {
      line-height: 1;
    }
  }
`

export const Team: React.FC<{
  persons: any | undefined
}> = ({ persons }) => {
  const [isActive, setIsActive] = React.useState(false)
  const [person, updatePerson] = React.useState<number>(0)
  const [activeSlide, updateActiveSlide] = React.useState<number>(0)
  const [swiper, setSwiper] = React.useState(null)

  if (persons === undefined) return null

  React.useEffect(() => {
    if (swiper !== null) {
      swiper.slideTo(person, 0)
    }
  }, [person])

  const next = () => {
    if (swiper !== null) {
      swiper.slideNext()
    }
  }

  const prev = () => {
    if (swiper !== null) {
      swiper.slidePrev()
    }
  }

  React.useEffect(() => {
    if (swiper !== null) {
      swiper.on("slideChange", function() {
        updateActiveSlide(swiper.activeIndex)
      })
    }
  }, [swiper])

  const checkForClick = React.useCallback(
    e => {
      if (!e.target.closest(".slide")) {
        setIsActive(false)
      }
    },
    [isActive]
  )

  React.useEffect(() => {
    document.addEventListener("click", checkForClick)

    return document.removeEventListener("click", checkForClick)
  }, [])

  return (
    <>
      <div
        css={`
          position: fixed;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          z-index: 999;
          overflow: hidden;
          display: flex;
          justify-content: center;
          align-items: center;

          .swiper-container {
            z-index: 99 !important;
            height: 100%;
          }
        `}
        style={{ pointerEvents: isActive ? "initial" : "none" }}
      >
        <div
          css={`
            background: rgba(0, 0, 0, 0.5);
            position: absolute;
            left: 0;
            top: 0;
            padding: 1rem;
            width: 100%;
            height: 100%;
            display: block;
            z-index: 98;
            transition: opacity 0.3s ease;
          `}
          style={{
            opacity: isActive ? 1 : 0,
            transitionDelay: isActive ? "0s" : "0.15s",
          }}
        />
        <Swiper {...params} getSwiper={setSwiper}>
          {persons.map(({ node }, i) => (
            <div
              key={i}
              css={`
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
              `}
            >
              <div
                className="slide"
                css={`
                  width: 100%;
                  max-width: 64rem;
                  background: ${props => props.theme.colors.background};
                  position: relative;
                  z-index: 99;
                  transition: transform 0.3s ease;
                  display: flex;
                `}
                style={{
                  transform: isActive ? "translateY(0vh)" : "translateY(100vh)",
                  transitionDelay: isActive ? "0.15s" : "0s",
                  transitionTimingFunction: isActive
                    ? "cubic-bezier(0.37, 0, 0.01, 1.01)"
                    : "cubic-bezier(0.93,-0.02, 0.49, 0.96)",
                }}
              >
                <div
                  css={`
                    flex-shrink: 1;
                    flex-grow: 0;
                    flex-basis: 100%;
                  `}
                >
                  <Img
                    fluid={
                      node.featuredImage?.localFile?.childImageSharp
                        ?.fluid as IFluidObject
                    }
                  />
                </div>
                <div
                  css={`
                    padding: 3rem;
                  `}
                >
                  <h3
                    css={`
                      font-size: calc(2rem + 1.5vw);
                      text-transform: uppercase;
                      line-height: 1;
                      font-family: ${props => props.theme.fonts.heading};
                    `}
                  >
                    {node.title}
                  </h3>
                  <Content
                    dangerouslySetInnerHTML={{
                      __html: node.content || "",
                    }}
                  />
                </div>
                <Button
                  onClick={() => setIsActive(false)}
                  css={`
                    position: absolute;
                    right: 1.5rem;
                    top: 1.5rem;
                    color: ${props => props.theme.colors.primary};
                  `}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                  >
                    <g fill="none" stroke="currentColor" strokeWidth="1.8">
                      <path d="M.636 13.364L13.364.636" />
                      <path d="M13.364 13.364L.636.636" />
                    </g>
                  </svg>
                </Button>
              </div>
            </div>
          ))}
        </Swiper>
        <div
          css={`
            background: ${props => props.theme.colors.background};
            height: 4rem;
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 100;
            transition: transform 0.3s ease;
          `}
          style={{
            transform: isActive ? "translateY(0rem)" : "translateY(4rem)",
            transitionDelay: isActive ? "0.15s" : "0s",
            transitionTimingFunction: isActive
              ? "cubic-bezier(0.37, 0, 0.01, 1.01)"
              : "cubic-bezier(0.93,-0.02, 0.49, 0.96)",
          }}
        >
          <Button onClick={() => prev()}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="47.221"
              height="15.485"
              viewBox="0 0 47.221 15.485"
            >
              <g fill="none" stroke="currentColor" strokeWidth="1.6">
                <path d="M1.721 7.742h45.5" />
                <path d="M8.308 14.919L1.131 7.742 8.308.565" />
              </g>
            </svg>
          </Button>
          <div
            css={`
              font-family: ${props => props.theme.fonts.heading};
              line-height: 1;
              text-transform: uppercase;
              font-size: 1rem;
              display: flex;
              align-items: center;
            `}
          >
            <span
              css={`
                color: ${props => props.theme.colors.primary};
                font-size: 1.5rem;
              `}
            >
              {activeSlide + 1}
            </span>
            <span
              css={`
                color: ${props => props.theme.colors.border};
                display: block;
                margin-left: 0.5rem;
                margin-right: 0.5rem;
              `}
            >
              /
            </span>
            <span>{persons.length}</span>
          </div>
          <Button onClick={() => next()}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="47.221"
              height="15.485"
              viewBox="0 0 47.221 15.485"
            >
              <g fill="none" stroke="currentColor" strokeWidth="1.6">
                <path d="M45.5 7.743H0" />
                <path d="M38.913.566l7.177 7.177-7.177 7.177" />
              </g>
            </svg>
          </Button>
        </div>
      </div>
      <ListItems>
        {persons.map(({ node }, i) => (
          <li
            key={node.id}
            onClick={() => {
              setIsActive(true)
              updatePerson(i)
            }}
          >
            <Img
              style={{ position: "absolute" }}
              fluid={
                node.featuredImage?.localFile?.childImageSharp
                  ?.fluid as IFluidObject
              }
            />
            <div>
              <h5>{node.title}</h5>
              <p>{node.persoon?.role}</p>
            </div>
          </li>
        ))}
      </ListItems>
    </>
  )
}

export default Team
